@use '../../styles/yr' as *;

.map-lightning-information-dialog {
  position: relative;
}

.map-lightning-information-dialog__scale-heading {
  margin-top: size(2);
  margin-bottom: size(1);
}

.map-lightning-information-dialog__scale-gradient {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.map-lightning-information-dialog__scale-gradient-value {
  display: flex;
  gap: size(1);

  height: fit-content;
}

.map-lightning-information-dialog__scale-gradient-color {
  min-width: size(3);
  height: size(3);
}

.map-lightning-information-dialog__body {
  margin-top: size(3);
}
