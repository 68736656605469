@use '../../styles/yr' as *;

.map-radar-information-dialog {
  position: relative;
}

.map-radar-information-dialog__body {
  display: block;
  margin-top: size(3);
}

.map-radar-information-dialog__scale-heading {
  margin-top: size(2);
  margin-bottom: size(1);
}

.map-radar-information-dialog__scale {
  position: relative;
}

.map-radar-information-dialog__scale-list {
  margin-left: 1.8rem;
}

.map-radar-information-dialog__scale-item {
  display: block;
  height: 1rem;
  line-height: 1rem;
  margin: 0;
  padding: 0;
}

.map-radar-information-dialog__scale-item--heavy,
.map-radar-information-dialog__scale-item--light {
  margin-top: 1rem;
}

// Colour gradient legend positioned to the left of the labels
.map-radar-information-dialog__scale-gradient {
  height: 5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1rem;
  display: flex;
  flex-direction: column;
}

.map-radar-information-dialog__scale-gradient-color {
  flex: 1;
}
