@use "sass:map";
@use './colors' as *;

$theme-background-primary: $base-color-white;
$theme-background-primary-transparent: rgba($base-color-white, 0);
$theme-background-secondary: $base-color-blue-faded;
$theme-text-primary: $base-color-black;
$theme-text-secondary: $base-color-grey;
$theme-text-link: $base-color-blue;
$theme-text-link-pressed: hotpink;
$theme-text-error: $base-color-red;
$theme-text-negative: $base-color-white;
$theme-divider: $base-color-border-grey;
$theme-graph-grid: $base-color-border-grey;
$theme-graph-grid-highlight: $base-color-blue-lightened;
$theme-button-border: $base-color-grey-test;
$theme-button-border-active: $base-color-blue;
$theme-rain: $base-color-blue;
$theme-rain-light: lighten($base-color-blue, 38%);
$theme-snow: $base-color-snowy-grey;
$theme-temperature-warm: $base-color-red;
$theme-temperature-cold: $base-color-blue;
$theme-wind: $base-color-black;
$theme-wind-colored: $base-color-purple;
$theme-wind-extreme: $base-color-black;
$theme-air-quality-severity-low: $base-color-green-2;
$theme-air-quality-severity-moderate: $base-color-yellow;
$theme-air-quality-severity-severe: $base-color-red;
$theme-air-quality-severity-extreme: $base-color-purple;
$theme-pollen-low: $base-color-green-2;
$theme-pollen-moderate: $base-color-yellow;
$theme-pollen-severe: $base-color-orange;
$theme-pollen-extreme: $base-color-red;
$theme-aurora-forecast-no-activity: $base-color-grey-faded;
$theme-aurora-forecast-low-activity: $base-color-turquoise;
$theme-aurora-forecast-high-activity: $base-color-violet;
$theme-favourite-icon-fill-active: $base-color-orange;
$theme-favourite-icon-outline-inactive: $base-color-grey;
$theme-backdrop: $base-color-black;
$theme-list-background: $base-color-grey-faded;
$theme-wave-height: $base-color-snowy-grey;
$theme-sea-current: $base-color-green-2;
$theme-water-level-prediction: $base-color-red;
$theme-water-level-forecast: $base-color-green-2;
$theme-pressure: $base-color-green-2;
$theme-humidity: $base-color-grey;
$theme-cloud-cover: $base-color-grey-faded;
$theme-sea-current-speed: $base-color-black;
$theme-wave-height: $base-color-black;
$theme-heavy-precipitation-colored: $base-color-purple-dark;

// These lightened colors have a contrast of AA or better when used as a background
// with text or an icon of the corresponding theme color on top.
$theme-rain--lightened: mix($base-color-blue, $base-color-white, $weight: 6%);
$theme-snow--lightened: mix($base-color-snowy-grey, $base-color-white, $weight: 6%);
$theme-temperature-warm--lightened: mix($base-color-red, $base-color-white, $weight: 6%);
$theme-temperature-cold--lightened: mix($base-color-blue, $base-color-white, $weight: 6%);
$theme-wind-colored--lightened: mix($base-color-purple, $base-color-white, $weight: 6%);

// These lightened colors have a contrast of AAA when used as a background
// with text or an icon with the primary text color on top.
$theme-air-quality-low--lightened: mix($base-color-green-2, $base-color-white, $weight: 20%);
$theme-air-quality-moderate--lightened: mix($base-color-yellow, $base-color-white, $weight: 25%);
$theme-air-quality-severe--lightened: mix($base-color-red, $base-color-white, $weight: 18%);
$theme-air-quality-extreme--lightened: mix($base-color-purple, $base-color-white, $weight: 20%);
$theme-pollen-low--lightened: mix($base-color-green-2, $base-color-white, $weight: 20%);
$theme-pollen-moderate--lightened: mix($base-color-yellow, $base-color-white, $weight: 25%);
$theme-pollen-severe--lightened: mix($base-color-orange, $base-color-white, $weight: 35%);
$theme-pollen-extreme--lightened: mix($base-color-red, $base-color-white, $weight: 18%);

$theme-frost-low--lightened: $base-color-green-faded;
$theme-frost-moderate--lightened: $base-color-orange-faded;
$theme-frost-extreme--lightened: $base-color-red-faded;

$theme-uv-none--lightened: mix($base-color-black, $base-color-white, $weight: 80%);
$theme-uv-low--lightened: mix($base-color-green-2, $base-color-white, $weight: 20%);
$theme-uv-moderate--lightened: mix($base-color-yellow, $base-color-white, $weight: 25%);
$theme-uv-high--lightened: mix($base-color-orange, $base-color-white, $weight: 25%);
$theme-uv-severe--lightened: mix($base-color-red, $base-color-white, $weight: 18%);
$theme-uv-extreme--lightened: mix($base-color-purple, $base-color-white, $weight: 20%);

$theme-sun-card-night: mix($base-color-coal, $base-color-blue-faded, 100%);
$theme-sun-card-astronomical-twilight: mix($base-color-coal, $base-color-blue-faded, 90%);
$theme-sun-card-nautical-twilight: mix($base-color-coal, $base-color-blue-faded, 80%);
$theme-sun-card-civil-twilight: mix($base-color-coal, $base-color-blue-faded, 70%);
$theme-sun-card-daylight: mix($base-color-coal, $base-color-blue-faded, 0%);

$theme-box-shadow: #{0 0.3px 0.9px rgba($base-color-black, 0.04), 0 0.9px 3.1px rgba($base-color-black, 0.07),
  0 4px 14px rgba($base-color-black, 0.1)};

$theme-box-shadow-elevated: #{0 0.6px 0.9px rgba($base-color-black, 0.07), 0 2px 3.1px rgba($base-color-black, 0.07),
  0 9px 14px rgba($base-color-black, 0.25)};
