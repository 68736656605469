@use '../../styles/yr' as *;

.map-wind-a11y-menu__dialog {
  display: flex;
  flex-direction: column;
  padding: size(2);
  width: fit-content;
  background-color: var(--color-background-base);
  border-radius: size(1);
  box-shadow: $theme-box-shadow-elevated;
}

.map-wind-a11y-menu__dialog-header-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: size(1.5);
}

.map-wind-a11y-menu__button {
  min-width: 217px;
}
