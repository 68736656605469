@use '../../styles/yr' as *;

.map-player {
  background-color: var(--color-background-base);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: size(2);
  padding: size(1) size(4) size(1) size(2);
  box-shadow: $theme-box-shadow-elevated;
  position: relative;
  z-index: 0;

  @include mq-gte($mq-map-desktop) {
    border-radius: size(1);
  }

  &[data-player-type='mini'] {
    border-radius: size(5);
    padding: size(0.5);
  }
}

.map-player__button {
  // Need to use a higher z-index than playhead so it is possible to click the button
  // when the playhead is at the start of the track.
  z-index: 3;
}

.map-player__container {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;

  .map-player[data-player-type='mini'] & {
    display: flex;
    gap: size(1);
  }
}

.map-player__current-time-label {
  place-self: center;

  // Use monospaced numbers so 1 is the same width as 9
  font-feature-settings: 'tnum';

  .map-player[data-player-type='mini'] & {
    order: 1;
  }
}

.map-player__track {
  position: relative;
  align-self: center;

  .map-player[data-player-type='mini'] & {
    width: rem(110px);
    height: 4px;
    border-radius: 4px;
  }
}

.map-player__range {
  width: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
}

.map-player__ticks {
  width: 100%;
  height: rem(44px);
  display: flex;
  align-items: center;
  position: absolute;
  transform: translate(0, -50%);
  z-index: 1;

  &[data-player-type='mini'] {
    visibility: hidden;
  }
}

.map-player__tick {
  position: absolute;
  width: rem(2px);
  background-color: var(--color-stroke-subtle-gray);
  height: rem(6px);

  &[data-is-played='true'] {
    background-color: var(--color-fill-active);
  }

  &[data-type='long'] {
    height: rem(10px);
  }
}

.map-player__playhead {
  background-color: transparent;
  position: absolute;
  height: size(6);
  width: size(6);
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  &:after {
    background-color: var(--color-fill-active);
    box-shadow: $theme-box-shadow;
    content: '';
    position: absolute;
    width: size(2);
    height: size(2);
    top: size(2);
    left: rem(17px);
    border-radius: 50%;
  }

  .map-player__range:focus ~ & {
    @include focus-style;
  }
}

.map-player__bottom-labels {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.map-player__now-label {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.map-player__end-label {
  justify-self: end;
}
