@use '../../styles/yr' as *;

.map-snackbar {
  background-color: var(--color-background-base);
  box-shadow: $theme-box-shadow;
  display: flex;
  position: relative;
  border-radius: size(0.5);
  padding: size(2);
  padding-left: rem(80px);
  width: fit-content;
  margin: 0 auto 1rem auto;
  // The symbol is rendered outside the popup, needs to be croped
  overflow: hidden;
}

.map-snackbar__info {
  display: flex;
  align-items: center;
}

.map-snackbar__text {
  width: size(28);
  max-width: 100%;
}

.map-snackbar__close-button {
  position: absolute;
  top: size(0.5);
  right: size(0.5);
}

.map-snackbar__symbol {
  height: size(12);
  width: size(12);
  max-width: none;
  position: absolute;
  top: rem(-10px);
  left: rem(-4px);
}
