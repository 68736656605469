@use '../../styles/yr' as *;

.map-lightning-toolbar {
  background-color: $base-color-white;
  margin: 0;
  box-shadow: $theme-box-shadow-elevated;
  padding: size(0.5);
  gap: size(1);
  display: flex;
  justify-content: center;

  @include mq-gte($mq-map-desktop) {
    border-radius: size(1);
    width: max-content;
    margin: 0 auto;
    justify-content: flex-start;
  }
}

.map-lightning-toolbar__button {
  min-height: rem(30px);
  flex: 1;
  white-space: nowrap;
  border-radius: rem(6px);

  &:hover {
    background-color: $base-color-blue-faded;
    color: $base-color-blue;
  }

  &[aria-pressed='true'] {
    background-color: $base-color-blue;
    color: $base-color-white;
  }

  @include mq-gte($mq-map-desktop) {
    padding: 0 size(2);
  }
}
