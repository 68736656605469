@use '../../styles/yr' as *;

.map-data-legend-container {
  padding: size(1);
  box-shadow: $theme-box-shadow-elevated;
  border-radius: size(1);
  background-color: var(--color-background-base);
  width: fit-content;
}

.map-data-legend {
  display: flex;
  flex-direction: column;
}

.map-data-legend__values,
.map-data-legend__label {
  display: flex;
  gap: size(0.5);
}

.map-data-legend__values-color {
  width: size(1);
  height: size(2.5);

  &[data-index='start'] {
    border-radius: size(1) size(1) 0 0;
  }

  &[data-index='end'] {
    border-radius: 0 0 size(1) size(1);
  }
}

.map-data-legend__value-labels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.map-data-legend__label-icon {
  margin-left: rem(-3px);
}

.map-data-legend__value-label {
  height: size(2.5);

  display: grid;
  align-items: center;
}

.map-data-legend__bottom-text {
  margin-top: size(0.5);
}
