@use '../../styles/yr' as *;

.map-temperature-toolbar {
  position: relative;
  margin: 0;

  @include mq-gt($mq-map-desktop) {
    margin: 0 auto;
    max-width: 40rem;
  }

  &[data-player-type='mini'] {
    margin: 0 auto;
    max-width: 19rem;
    height: rem(40px);
  }
}
