@use '../../styles/yr' as *;

.map-weather-toolbar {
  background-color: var(--color-background-muted-gray);
  margin: 0;
  box-shadow: $theme-box-shadow-elevated;
  padding: size(0.5);
  gap: size(1);
  display: flex;
  justify-content: center;

  @include mq-gte($mq-map-desktop) {
    border-radius: rem(99px);

    width: max-content;
    margin: 0 auto;
    justify-content: flex-start;
  }
}

.map-weather-toolbar__button {
  min-height: rem(30px);
  flex: 1;
  white-space: nowrap;
  border-radius: rem(99px);

  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--color-button-primary-border-rest);
    background-color: var(--color-button-primary-bg-hover);
    color: var(--color-button-primary-fg-rest);
  }

  &[aria-pressed='true'] {
    border: 1px solid var(--color-button-highlight-bg-rest);
    background-color: var(--color-button-highlight-bg-rest);
    color: var(--color-button-highlight-fg-rest);
  }

  @include mq-gte($mq-map-desktop) {
    padding: 0 size(2);
  }
}
