@use '../../styles/yr' as *;

.map-popup {
  background-color: var(--color-background-base);
  box-shadow: $theme-box-shadow;
  border-radius: size(1);
  padding: size(2);
  position: relative;
  min-width: rem(264px);
  // max-width: size(38);
  max-width: rem(264px);

  @include mq-gte($mq-map-desktop) {
    max-width: size(47.5);
    &[data-layer='weather'] {
      min-width: size(47.5);
    }
  }
}

.map-popup__subtitle {
  padding-bottom: size(1);
}

.map-popup__navigation-button {
  margin-top: size(2);
}

.map-popup__close-button {
  position: absolute;
  top: size(1);
  right: size(1);
}

.map-popup__location-list {
  margin-top: size(2);
}

.map-popup__location-list-header {
  margin-top: size(1);
}

.map-popup__location-list-distance-text-loader {
  @include mq-lt($mq-map-desktop) {
    display: none;
  }
}

.map-popup__location-list-distance-text-loader-mobile {
  @include mq-gte($mq-map-desktop) {
    display: none;
  }
}

.map-type-wind__popup-content {
  display: flex;
  align-items: center;
  gap: size(1);
}
